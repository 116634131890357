/* eslint-disable object-curly-newline */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { useLocation } from '@reach/router';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import '../components/Sections/Jobs/_jobs.scss';

const JobArticle = ({ data }) => {
  const { text, title, content, metaData, image } = data.jobs;
  const { global } = data;
  const location = useLocation();

  return (
    <>
      <Seo seo={metaData} global={global} />
      <Layout global={global}>
        <div className="job-wrapper">
          <h2 className="job-title">{title}</h2>
          <p className="job-description">{text}</p>
          <div className="social-media social-media-job">
            <FacebookShareButton url={location.href} quote={text}>
              <FacebookIcon size={30} round />
            </FacebookShareButton>
            <TwitterShareButton url={location.href} title={title}>
              <TwitterIcon size={30} round />
            </TwitterShareButton>
            <LinkedinShareButton
              url={location.href}
              title={title}
              summary={text}
            >
              <LinkedinIcon size={30} round />
            </LinkedinShareButton>
          </div>
          {image.url ? (
            <img
              alt={`${title} article`}
              src={image.url}
              className="job-image"
            />
          ) : null}
          <div className="job-content">
            <ReactMarkdown children={content} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default JobArticle;

export const query = graphql`
  query GetSingleJob($slug: String) {
    global: strapiGlobal {
      jumpToTop {
        title
      }
      id
      metaData {
        title
        description
        image {
          url
        }
      }
      navbar {
        links {
          url
          title
        }
      }
      footer {
        contactIsmir
        footerInfo
        headerEngagementModels
        headerHome
        headerTeam
        headerTestimonials
        textHome
        textEngagementModels
        textTeam
        textTestimonials
        facebookUrl
        instagramUrl
        linkedinUrl
        dribbbleUrl
      }
    }
    jobs: strapiJobs(slug: { eq: $slug }) {
      text
      title
      subtitle
      content
      image {
        url
      }
      metaData {
        description
        title
        image {
          url
        }
      }
    }
  }
`;
